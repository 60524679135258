import { FetchService } from './FetchService';
import { ForgotRequestProps } from 'modules/users/use-cases/ForgotPassword/types';
import { LoginProps, LoginResponse } from 'modules/users/use-cases/Login/types';
import { ResetPasswordProps } from 'modules/users/use-cases/ResetPassword/types';

export const login = async (loginProps: LoginProps) =>
  FetchService<LoginResponse, LoginProps>(
    'POST',
    '/api/auth/login',
    loginProps
  );

export const forgot = async (forgotProps: ForgotRequestProps) =>
  FetchService<boolean, ForgotRequestProps>(
    'POST',
    '/api/auth/forgot',
    forgotProps
  );

export const logout = async () =>
  FetchService<void, void>('POST', '/api/auth/logout');

export const reset = async (resetProps: ResetPasswordProps) =>
  FetchService<LoginResponse, ResetPasswordProps>(
    'POST',
    '/api/auth/reset',
    resetProps
  );
